<template>
<div style="max-height:800px">
  <router-view :key="$route.path"></router-view>
</div>
</template>

<script>
 

export default {

  name:'app',
  
}
</script>
<style>
*{
  text-decoration: none;
  padding: 0px;
  margin:0px;
  color:#464855;
  font-weight: 500;
}
body{
      font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
}
h3{
  font: size 1.51rem
}
body{
  background-color: #F4F5FA;
}

</style>
